// App.jsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InputVariables from './InputVariables';
import Security from './Security';
import LoginPage from './LoginPage'; // Create a LoginPage component for authentication

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Security><InputVariables /></Security>} />
      {/* Add more routes as needed */}
    </Routes>
  );
};

export default App;
