import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import ListDivider from '@mui/joy/ListDivider';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import * as XLSX from 'xlsx';
import { getDatabase, databaseRef, push, set, get, onValue, off } from './firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const database = getDatabase();


function createData(name, price, date, pushKey = null) {
  return { name, price, date, pushKey };
}

const initialRows = [];

export default function InputVariables() {
  const [articleName, setArticleName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [dateValue, setDateValue] = useState(new Date());
  const [rows, setRows] = useState(initialRows);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [ascendingOrder, setAscendingOrder] = useState(true);

  const handleDeleteAll = async () => {
    const confirmed = window.confirm('Are you sure you want to delete all data?');
  
    if (confirmed) {
      try {
        await set(databaseRef(database, '/rows'), null);
        setRows([]);
      } catch (error) {
        console.error('Error deleting all rows:', error);
      }
    }
  };

  const forceRerender = () => {
    setRows((prevRows) => [...prevRows]);
  };

  const handleButtonClick = async () => {
    if (articleName.trim() !== '' && inputValue.trim() !== '') {
      let newRow;

      if (inputValue.startsWith('*')) {
        newRow = createData(articleName, inputValue, dateValue.toDateString(), selectedRow ? selectedRow.pushKey : null);
      } else {
        newRow = createData(articleName, parseFloat(inputValue), dateValue.toDateString(), selectedRow ? selectedRow.pushKey : null);
      }

      if (selectedRow !== null) {
        await set(databaseRef(database, `/rows/${selectedRow.pushKey}`), newRow);
        setSelectedRow(null);
      } else {
        const newRef = push(databaseRef(database, '/rows'));
        newRow.pushKey = newRef.key;
        await set(newRef, newRow);
      }

      const snapshot = await get(databaseRef(database, '/rows'));
      const data = snapshot.val();

      if (data) {
        setRows(Object.values(data));
      }

      setArticleName('');
      setInputValue('');
      setDateValue(new Date());
      forceRerender();
    }
  };

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          try {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            setRows(excelRows);
            await updateFirebaseData(excelRows);
          } catch (error) {
            console.error('Error reading Excel file:', error);
          }
        };

        reader.readAsBinaryString(file);
      }
    } catch (error) {
      console.error('Error handling file upload:', error);
    }
  };

  const handleArticleNameChange = (event) => {
    setArticleName(event.target.value);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const validValue = value.match(/^(-|\+|\*)?[0-9]*\.?[0-9]*$/);

    setInputValue(validValue ? validValue[0] : '');
  };

  const handleDateChange = (event) => {
    setDateValue(new Date(event.target.value));
  };

  const handleDeleteRow = async (pushKey) => {
    try {
      console.log(`Deleting row with pushKey: ${pushKey}`);
      await set(databaseRef(database, `/rows/${pushKey}`), null);
      console.log(`Row with pushKey ${pushKey} deleted successfully.`);
      setRows((prevRows) => prevRows.filter((row) => row.pushKey !== pushKey));
    } catch (error) {
      console.error('Error deleting row:', error);
    }
  };

  const handleEditRow = (pushKey) => {
    const selectedRow = rows.find((row) => row.pushKey === pushKey);

    if (selectedRow) {
      setArticleName(selectedRow.name);
      setInputValue(selectedRow.price.toString());
      setDateValue(new Date(selectedRow.date));
      setSelectedRow(selectedRow);
    }
  };

  const calculateTotalPrice = () => {
    const total = rows.reduce((sum, row) => {
      const startsWithAsterisk = String(row.price).startsWith('*');

      if (!startsWithAsterisk) {
        const numericValue = parseFloat(row.price);
        return sum + numericValue;
      }

      return sum;
    }, 0);

    return total.toFixed(2);
  };

  const exportToExcel = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${day}/${month}/${year}`;

    const filename = `export_${formattedDate}.xlsx`;

    const exportRows = [...rows];

    const ws = XLSX.utils.json_to_sheet(exportRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveExcelFile(excelBuffer, filename);
  };

  const sortRows = (rows, sortBy, ascendingOrder) => {
    return [...rows].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return sortBy === 'name' ? (ascendingOrder ? -1 : 1) : -1;
      }

      if (nameA > nameB) {
        return sortBy === 'name' ? (ascendingOrder ? 1 : -1) : 1;
      }

      return 0;
    });
  };

  const handleSort = (column) => {
    setSortBy(column);
    setAscendingOrder((prevOrder) => !prevOrder);
  };

  const saveExcelFile = (data, fileName) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const updateFirebaseData = async (excelRows) => {
    try {
      for (const row of excelRows) {
        if (row.pushKey) {
          await set(databaseRef(database, `/rows/${row.pushKey}`), row);
        } else {
          const newRef = push(databaseRef(database, '/rows'));
          row.pushKey = newRef.key;
          await set(newRef, row);
        }
      }

      console.log('Firebase data updated successfully.');
    } catch (error) {
      console.error('Error updating Firebase data:', error);
    }
  };

  useEffect(() => {
    const fetchData = () => {
      const rowsRef = databaseRef(database, '/rows');

      onValue(rowsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setRows(Object.values(data));
        }
      });
    };

    fetchData();

    return () => {
      const rowsRef = databaseRef(database, '/rows');
      off(rowsRef, 'value');
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        minHeight: '100vh',
        width: '100%',
        padding: '2vh 2%',
        alignItems: 'center',
      }}
    >
      {/* Input fields and buttons */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Input
          placeholder="Article Name"
          value={articleName}
          onChange={handleArticleNameChange}
        />
        <Input
          placeholder="Price"
          value={inputValue}
          onChange={handleInputChange}
        />
        <Input
          type="date"
          value={dateValue.toISOString().slice(0, 10)}
          onChange={handleDateChange}
        />
        <Button variant="soft" onClick={handleButtonClick}>
    {selectedRow !== null ? 'Edit' : 'Add'}
  </Button>
  <label
    style={{
      display: 'inline-block',
      padding: '8px 12px',
      backgroundColor: '#2196F3',
      color: 'white',
      cursor: 'pointer',
      borderRadius: '4px',
    }}
  >
    Import Excel
    <input
      type="file"
      accept=".xlsx"
      style={{
        display: 'none',
      }}
      onChange={handleFileUpload}
    />
  </label>
      </Box>

      {/* Divider */}
      <ListDivider component="hr" />

      {/* Data table */}
      <div style={{ maxHeight: '40vh',width:"44vh", overflow: 'scroll'}}>
        <Sheet>
          <Table stickyHeader style={{}}>
            <thead>
              <tr>
                <th onClick={() => handleSort('name')}>
                  Article Name
                  {sortBy === 'name' && (
                    <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={ascendingOrder ? faSortUp : faSortDown} />
                    </span>
                  )}
                </th>
                <th>Price&nbsp;(dh)</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortRows(rows, sortBy, ascendingOrder).map((row) => {
                if (!row.pushKey) {
                  return null;
                }

                return (
                  <tr key={row.pushKey}>
                    <td>
                      <span
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => {
                          handleEditRow(row.pushKey);
                          setSelectedRow(row);
                        }}
                      >
                        {row.name}
                      </span>
                    </td>
                    <td>{typeof row.price === 'number' ? row.price.toFixed(2) : row.price}</td>
                    <td>{row.date}</td>
                    <td>
                      <Button variant="soft" onClick={() => handleDeleteRow(row.pushKey)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Sheet>
      </div>

      {/* Divider */}
      <ListDivider component="hr" />

      {/* Total price */}
      <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <strong>Total Price:</strong>
          <div>{calculateTotalPrice()} DH</div>

          <Button variant="soft" onClick={exportToExcel}>
            Export to Excel
          </Button>
          <Button variant="soft" onClick={handleDeleteAll} style={{ marginTop: '10px' }}>
            Delete All
          </Button>
        </Box>
      </div>
    </Box>
  );
}
