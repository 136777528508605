// LoginPage.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signInWithEmailAndPassword } from './firebase';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Authenticate the user using Firebase authentication
      await signInWithEmailAndPassword(auth, email, password);
      // If successful, navigate to the protected route (e.g., InputVariables)
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div style={{ width: '30vh', margin: 'auto',marginTop:"20vh",backgroundColor:"gray",padding:"5vh",borderRadius:"5vh", textAlign: 'center' }}>
      <h2 style={{color:"white"}}>Login</h2>
      <form onSubmit={handleLogin}>
        <Input
          style={{ marginBottom: '3vh' }}
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          style={{ marginBottom: '5vh' }}
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button variant="soft" type="submit" >
          Login
        </Button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default LoginPage;
