// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
  getDatabase,
  ref as databaseRef,
  push,
  set,
  get,
  onValue,
  off,
} from "firebase/database";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmMF9wwBI0swU54bYLG1zcNtPqtHTR-Gk",
  authDomain: "calcul-2679d.firebaseapp.com",
  databaseURL: "https://calcul-2679d-default-rtdb.firebaseio.com",
  projectId: "calcul-2679d",
  storageBucket: "calcul-2679d.appspot.com",
  messagingSenderId: "291995062478",
  appId: "1:291995062478:web:7fb117468ae96624b4809f",
  measurementId: "G-64KP0XQ0VD",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
  auth,
  getDatabase,
  signInWithEmailAndPassword,
  push,
  databaseRef, // Rename ref to databaseRef
  set,
  get,
  onValue,
  off,
};
